<template>
  <div class="page__wrapper">
    <ag-grid
      ref="reportReviewGrid"
      style="width: 100%; height: 100%"
      pagination
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :framework-components="frameworkComponents"
      :grid-options="gridOptions"
    >
      <!-- 查询条件 -->
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button @click="handleDataSearch">
            {{ $t("operation.search") }}
          </el-button>
          <el-button
            v-permission="'thirdParty_reportReview:publish'"
            type="primary"
            @click="handlePublishClick"
          >
            {{ $t("operation.publish") }}
          </el-button>
          <el-button
            v-permission="'thirdParty_reportReview:import'"
            type="primary"
            @click="isShowUploader = true"
          >
            {{ $t("thirdParty_reportReview.import") }}
          </el-button>
          <el-button
            v-permission="'thirdParty_reportReview:export'"
            type="primary"
            @click="handleExportClick"
          >
            {{ $t("thirdParty_reportReview.export") }}
          </el-button>
        </template>
      </simple-form>
    </ag-grid>
    <!-- 详情页 -->
    <detail-dialog
      :show-flag.sync="isShowDetailDialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
      :is-third-party="true"
    />
    <!-- 详情页 New -->
    <new-detail-dialog
      :show-flag.sync="isShowNewDetailDialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />
    <!-- 详情页 2022 -->
    <detail-2022-dialog
      :show-flag.sync="isShowDetail2022Dialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />
    <!-- 详情页 202206 -->
    <detail-202206-dialog
      :show-flag.sync="isShowDetail202206Dialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />
    <!-- 详情页 202308 -->
    <detail-202308-dialog
      :show-flag.sync="isShowDetail202308Dialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />

    <anomaly
      :show-flag.sync="isShowAnomalyDialog"
      :view-flag="isViewFlag"
      :is-third-party="true"
      :detail-data="rowData"
    />
    <other-docs
      :show-flag.sync="isShowOtherDocs"
      :detail-data="rowData"
    />
    <!-- 文件上传 -->
    <!-- <simple-upload
      ref="uploader"
      url="/jlr/tt/exceptionKpi/import"
      :show-flag.sync="isShowUploader"
      @on-success="handleUploadSuccess"
    /> -->
    <simple-upload
      ref="uploader"
      url="/download/tt/exceptionKpi/import"
      :show-flag.sync="isShowUploader"
      @on-success="handleUploadSuccess"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import detailDialog from '../../dealer/finance/detail'
import newDetailDialog from '../../dealer/finance/detailNew'
import detail2022Dialog from '../../dealer/finance/detail2022'
import detail202206Dialog from '../../dealer/finance/detail202206'
import detail202308Dialog from '../../dealer/finance/detail202308'
import anomaly from '../../dealer/anomaly/anomaly'
import otherDocs from './otherDocs'

const BASEURL = {
  list: '/jlr/tt/selfExaminationData/listPeriod',
  office: '/system/office/list',
  release: '/jlr/tt/selfExaminationData/release',
  submit: '/jlr/tt/selfExaminationData/submitData',
  rollback: '/jlr/tt/selfExaminationData/rollback',
  rollbackToV2: '/jlr/tt/selfExaminationData/rollbackToV2',
  export: '/jlr/tt/exceptionKpi/export',
  aloneRelease: '/jlr/tt/selfExaminationData/aloneRelease'
}

/** 自定义操作列 */
const Operation = Vue.extend({
  inject: [
    'handleDataSearch',
    'handleViewClick',
    'handleCheckClick',
    'handleAjustEntriesClick',
    'handleOtherDocsClick',
    'handleSubmitClick',
    'handleRollbackClick',
    'handleRollbackToV2Click',
    'handleSinglePublishClick'
  ],
  template: `<div style="height:100%;text-align:center;">
      <el-tooltip effect="light" :content="$t('operation.preview')">
        <el-button type="text" v-permission="'thirdParty_reportReview:view'" style="font-size:14px;" icon="el-icon-search"
          @click="handleViewClick(params.data)" :disabled="!params.data.id" />
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('thirdParty_reportReview.anomalyReview')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-s-check" v-permission="'thirdParty_reportReview:anomaly'"
          @click="handleCheckClick(params.data)" :disabled="!params.data.id" />
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('thirdParty_reportReview.adjustEntries')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-s-open" v-permission="'thirdParty_reportReview:ajustEntries'"
          :disabled="params.data.isSubmit==='Y' || !params.data.id" @click="handleAjustEntriesClick(params.data)" />
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('thirdParty_reportReview.otherDocs')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-document" v-permission="'thirdParty_reportReview:otherDocs'"
          :style="{color: (!params.data.docCount || (params.data.docCount && params.data.docCount <= 0) ? '#C0C4CC':'#409EFF')}"
          @click="handleOtherDocsClick(params.data)" />
      </el-tooltip>
      <el-tooltip effect="light" style="font-size:14px;" :content="$t('operation.submit')">
        <el-button type="text" icon="el-icon-success" :disabled="params.data.isSubmit==='Y' || !params.data.id"
        v-permission="'thirdParty_reportReview:submit'" @click="handleSubmitClick(params.data)" />
      </el-tooltip>
      <el-tooltip effect="light" style="font-size:14px;" :content="$t('operation.back')">
        <el-button type="text" icon="el-icon-back" :disabled="params.data.isSubmit!=='Y'"
        v-permission="'thirdParty_reportReview:rollback'" @click="handleRollbackClick(params.data)" />
      </el-tooltip>
      <el-tooltip effect="light" style="font-size:14px;" :content="$t('thirdParty_reportReview.backToV2')">
        <el-button type="text" icon="el-icon-top" :disabled="params.data.isSubmit==='Y'" @click="handleRollbackToV2Click(params.data)" />
      </el-tooltip>
      <el-tooltip effect="light" style="font-size:14px;" :content="$t('operation.publish')">
        <el-button type="text" icon="el-icon-s-promotion" :disabled="params.data.isSubmit!=='Y' || !params.data.id || params.data.reviewRelease==='tt_review_release_1'"
        v-permission="'thirdParty_reportReview:publish'" @click="handleSinglePublishClick(params.data)" />
      </el-tooltip>
    </div>`
})
export default {
  name: 'ReportReview',
  components: {
    detailDialog,
    anomaly,
    otherDocs,
    newDetailDialog,
    detail2022Dialog,
    detail202206Dialog,
    detail202308Dialog
  },
  data () {
    return {
      searchModel: {
        period: this.$moment().subtract(1, 'months').format('YYYYMM')
      },
      tableData: [],
      rowData: {},
      count: 0,
      dealerList: [],
      groupList: [],
      gridOptions: {},
      isViewFlag: false,
      isShowDetailDialog: false,
      isShowNewDetailDialog: false,
      isShowAnomalyDialog: false,
      isShowOtherDocs: false,
      dealerLoading: false,
      groupLoading: false,
      frameworkComponents: {},
      submitting: false,
      isShowUploader: false,
      isShowDetail2022Dialog: false,
      isShowDetail202206Dialog: false,
      isShowDetail202308Dialog: false
    }
  },
  provide () {
    return {
      handleDataSearch: this.handleDataSearch,
      handleViewClick: this.handleViewClick,
      handleCheckClick: this.handleCheckClick,
      handleSubmitClick: this.handleSubmitClick,
      handleRollbackClick: this.handleRollbackClick,
      handleRollbackToV2Click: this.handleRollbackToV2Click,
      handleAjustEntriesClick: this.handleAjustEntriesClick,
      handleOtherDocsClick: this.handleOtherDocsClick,
      handleSinglePublishClick: this.handleSinglePublishClick
    }
  },
  computed: {
    searchFormField () {
      return [
        {
          prop: 'period',
          type: 'Datepicker',
          col: { xs: 4, sm: 4, md: 4 },
          label: this.$t('dealer_finance.month'),
          component: { type: 'month', valueFormat: 'yyyyMM', clearable: false }
        },
        {
          prop: 'region',
          type: 'Select',
          col: { xs: 4, sm: 4, md: 4 },
          component: {
            optionList: this.$getDictList('sys_office_region'),
            clearable: true
          },
          label: this.$t('dealer_finance.area')
        },
        {
          prop: 'dealerParent',
          type: 'SearchableInput',
          col: { xs: 4, sm: 4, md: 4 },
          label: this.$t('dealer_finance.group'),
          component: {
            remoteMethod: this.getGroupList,
            loading: this.groupLoading,
            optionList: this.groupList,
            valueKey: 'id',
            clearable: true
          },
          event: {
            focus: () => {
              this.getGroupList()
            }
          }
        },
        {
          prop: 'dealer',
          type: 'SearchableInput',
          col: { xs: 4, sm: 4, md: 4 },
          label: this.$t('dealer_finance.dealer'),
          component: {
            remoteMethod: this.getDealerList,
            loading: this.dealerLoading,
            optionList: this.dealerList,
            valueKey: 'id',
            clearable: true
          },
          event: {
            focus: () => {
              this.getDealerList()
            }
          }
        },
        {
          slotName: 'tableOperation',
          col: { xs: 8, sm: 8, md: 8 },
          style: { textAlign: 'right' },
          labelWidth: '0'
        }
      ]
    },
    columnDefs () {
      return [
        {
          headerName: this.$t('dealer_finance.dealerName'),
          field: 'dealer.name',
          pinned: 'left',
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.dealerCode'),
          field: 'dealer.code',
          pinned: 'left',
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.area'),
          field: 'region',
          valueFormatter: (params) =>
            this.$getDictLabel({
              type: 'sys_office_region',
              value: params.value
            }),
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.group'),
          field: 'dealerParent',
          valueFormatter: (params) =>
            params.data.dealerParent ? params.data.dealerParent.name || '' : '',
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return valueA && valueB
              ? valueA.name.localeCompare(valueB.name)
              : -1
          },
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.version'),
          field: 'version',
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.cycle'),
          field: 'period',
          valueFormatter: (params) =>
            params.value
              ? this.$moment(params.value, 'YYYYMM').format('YYYY年MM月')
              : '',
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.reporter'),
          field: 'compiler',
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.reporterPosition'),
          field: 'compilerPosition',
          minWidth: 160
        },
        // {
        //   headerName: this.$t('dealer_finance.reporterPhone'),
        //   field: 'compilerPhone',
        //   minWidth: 160
        // },
        {
          headerName: this.$t('dealer_finance.reporterEmail'),
          field: 'compilerMail',
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.reviewConfirmStatus'),
          field: 'reviewConfirmStatus',
          valueFormatter: (params) =>
            this.$getDictLabel({
              type: 'tt_review_confirm_status',
              value: params.value
            }),
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.isSubmit'),
          field: 'isSubmit',
          valueFormatter: (params) =>
            this.$getDictLabel({ type: 'yes_no', value: params.value }),
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.isPublish'),
          field: 'reviewRelease',
          valueFormatter: (params) =>
            this.$getDictLabel({
              type: 'tt_review_release',
              value: params.value
            }),
          minWidth: 120
        },
        {
          headerName: this.$t('field.operation'),
          width: 246,
          suppressSizeToFit: true,
          pinned: 'right',
          cellRenderer: 'Operation'
        }
      ]
    }
  },
  beforeMount () {
    this.frameworkComponents = { Operation: Operation }
  },
  mounted () {
    this.handleDataSearch()
  },
  methods: {
    handleDataSearch () {
      const params = Object.assign(
        {},
        this.$utils.parseQueryCondition(this.searchModel, this.searchFormField)
      )
      params.filter.push({ left: 'bizStatus', operate: '=', right: 'V3' })
      if (this.searchModel.dealer) {
        params.filter.push({
          left: 'dealer.id',
          operate: '=',
          right: this.searchModel.dealer.id
        })
      }
      if (this.searchModel.dealerParent) {
        params.filter.push({
          left: 'dealerParent.id',
          operate: '=',
          right: this.searchModel.dealerParent.id
        })
      }
      const loadingFlag = this.$loading({
        fullscreen: false,
        target: this.$el,
        text: this.$t('tip.systemLoading')
      })
      this.$axios
        .post(BASEURL.list, params)
        .then((resp) => {
          const respData = resp.data
          this.tableData = respData.list
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleSubmitClick (row) {
      if (this.submitting) return
      this.submitting = true
      this.$msgbox({
        title: this.$t('tip.warning'),
        message: this.$t('dealer_finance.submitTip'),
        type: 'warning',
        showCancelButton: true,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            done()
            const loadingFlag = this.$loading({
              fullscreen: false,
              target: this.$el,
              text: this.$t('tip.systemLoading')
            })
            this.$axios
              .post(BASEURL.submit, { id: row.id, copyNewVersion: false })
              .then((resp) => {
                const messages = resp.data.itemList
                if (!messages || (messages && !messages.length)) {
                  this.$message({
                    type: 'success',
                    message: this.$t('tip.approveSuccess')
                  })
                } else {
                  let str = ''
                  messages.forEach((ele) => {
                    str +=
                      '<div style="padding:8px;">' + ele.message + '</div>'
                  })
                  this.$message({
                    type: 'error',
                    duration: 0,
                    offset: 100,
                    showClose: true,
                    dangerouslyUseHTMLString: true,
                    message: str
                  })
                }
              })
              .finally(() => {
                this.submitting = false
                loadingFlag.close()
                this.handleDataSearch()
              })
          } else {
            done()
            this.submitting = false
          }
        }
      }).then(() => { })
    },
    handleRollbackClick (row) {
      const loadingFlag = this.$loading({
        target: this.$el.querySelector('.el-dialog')
      })
      this.$axios
        .post(BASEURL.rollback, { id: row.id })
        .then((resp) => {
          this.$message({
            type: 'success',
            message: this.$t('tip.rollbackSuccess')
          })
        })
        .finally(() => {
          loadingFlag.close()
          this.handleDataSearch()
        })
    },
    handleRollbackToV2Click (row) {
      const loadingFlag = this.$loading({
        target: this.$el.querySelector('.el-dialog')
      })
      this.$axios
        .post(BASEURL.rollbackToV2, { id: row.id })
        .then((resp) => {
          this.$message({
            type: 'success',
            message: this.$t('tip.rollbackSuccess')
          })
        })
        .finally(() => {
          loadingFlag.close()
          this.handleDataSearch()
        })
    },
    handleSinglePublishClick (row) {
      const loadingFlag = this.$loading({
        target: this.$el.querySelector('.el-dialog')
      })
      this.$axios
        .post(BASEURL.aloneRelease, { id: row.id })
        .then((resp) => {
          this.$message({
            type: 'success',
            message: this.$t('tip.publishSuccess')
          })
        })
        .finally(() => {
          loadingFlag.close()
          this.handleDataSearch()
        })
    },
    handleViewClick (rowData) {
      this.rowData = this.$_.cloneDeep(rowData)
      this.isViewFlag = true
      if (
        this.$moment(rowData.period, 'YYYYMM') < this.$moment('202101', 'YYYYMM')
      ) {
        this.isShowDetailDialog = true
      } else if (
        this.$moment(rowData.period, 'YYYYMM') < this.$moment('202201', 'YYYYMM')
      ) {
        this.isShowNewDetailDialog = true
      } else if (
        this.$moment(rowData.period, 'YYYYMM') < this.$moment('202206', 'YYYYMM')
      ) {
        this.isShowDetail2022Dialog = true
      } else if (this.$moment(rowData.period, 'YYYYMM') < this.$moment('202308', 'YYYYMM')) {
        this.isShowDetail202206Dialog = true
      } else {
        this.isShowDetail202308Dialog = true
      }
    },
    handleCheckClick (rowData) {
      this.isViewFlag = rowData.isSubmit === 'Y'
      this.rowData = this.$_.cloneDeep(rowData)
      this.isShowAnomalyDialog = true
    },
    handleAjustEntriesClick (rowData) {
      this.rowData = this.$_.cloneDeep(rowData)
      this.isViewFlag = false
      if (
        this.$moment(rowData.period, 'YYYYMM') < this.$moment('202101', 'YYYYMM')
      ) {
        this.isShowDetailDialog = true
      } else if (
        this.$moment(rowData.period, 'YYYYMM') < this.$moment('202201', 'YYYYMM')
      ) {
        this.isShowNewDetailDialog = true
      } else if (
        this.$moment(rowData.period, 'YYYYMM') < this.$moment('202206', 'YYYYMM')
      ) {
        this.isShowDetail2022Dialog = true
      } else if (
        this.$moment(rowData.period, 'YYYYMM') < this.$moment('202308', 'YYYYMM')
      ) {
        this.isShowDetail202206Dialog = true
      } else {
        this.isShowDetail202308Dialog = true
      }
    },
    handleOtherDocsClick (rowData) {
      this.rowData = this.$_.cloneDeep(rowData)
      this.isShowOtherDocs = true
    },
    getDealerList (name) {
      this.dealerLoading = true
      const params = {
        filter: [
          { left: 'category', operate: '=', right: 'sys_office_category_3' }
        ]
      }
      if (name) {
        params.filter.push({
          left: { left: 'name', operate: 'like', right: name },
          operate: 'or',
          right: { left: 'code', operate: 'like', right: name }
        })
      }
      this.$axios
        .post(BASEURL.office, params)
        .then((resp) => {
          const respData = resp.data
          this.dealerList = respData.list.map((item) => {
            return { key: item.id, value: item, label: item.name }
          })
        })
        .finally(() => {
          this.dealerLoading = false
        })
    },
    getGroupList (name) {
      this.groupLoading = true
      const params = {
        filter: [
          { left: 'category', operate: '=', right: 'sys_office_category_2' }
        ]
      }
      if (name) {
        params.filter.push({
          left: { left: 'name', operate: 'like', right: name },
          operate: 'or',
          right: { left: 'nameEn', operate: 'like', right: name }
        })
      }
      this.$axios
        .post(BASEURL.office, params)
        .then((resp) => {
          const respData = resp.data
          this.groupList = respData.list.map((item) => {
            return { key: item.id, value: item, label: item.name }
          })
        })
        .finally(() => {
          this.groupLoading = false
        })
    },
    handlePublishClick () {
      const params = {
        filter: [
          { left: 'period', operate: '=', right: this.searchModel.period }
        ]
      }
      const loadingFlag = this.$loading({
        fullscreen: false,
        target: this.$el,
        text: this.$t('tip.systemLoading')
      })
      this.$axios
        .post(BASEURL.release, params)
        .then((resp) => {
          this.handleDataSearch()
          this.$message({
            type: 'success',
            message: this.$t('tip.publishSuccess')
          })
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleUploadSuccess () {
      this.isShowUploader = false
      this.handleDataSearch()
    },
    handleExportClick () {
      const loadingFlag = this.$loading({
        fullscreen: false,
        target: this.$el,
        text: this.$t('tip.systemLoading')
      })
      const params = {}
      params.period = this.searchModel.period
      params.page = { orderBy: 'dealer.code' }
      this.$axios
        .post(BASEURL.export, params)
        .then((resp) => {
          this.$message({
            type: 'success',
            message: this.$t('tip.exportSuccess')
          })
        })
        .finally(() => {
          loadingFlag.close()
        })
    }
  }
}
</script>
<style lang="less" scoped>
.page__wrapper {
  width: 100%;
  height: 100%;
}
</style>
